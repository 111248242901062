import memoize from 'memoize-one';
import { parseQuery } from './url';

export interface TokenDecoded {
  sub: string;
  iat: number;
  exp: number;
}

export function parseJWT<T = {}>(token: string) {
  try {
    return JSON.parse(decodeURIComponent(escape(window.atob(token.split('.')[1].replace('-', '+').replace('_', '/'))))) as TokenDecoded & T;
  } catch (e) {
    return undefined;
  }
}

export function getTokenExpirationDate({ iat, exp }: TokenDecoded): number {
  // returns in milliseconds
  return Date.now() + (exp - iat) * 1000;
}

export function isTokenExpired({ exp }: TokenDecoded): boolean {
  return exp < Date.now() / 1000;
}

function verifyQueryTokenImpl<T = {}>(query: string) {
  const { token } = parseQuery<{ token: string }>(query);
  const jwt = parseJWT<T>(token);
  return { token, jwt, expired: jwt && isTokenExpired(jwt), expirationDate: jwt && getTokenExpirationDate(jwt) };
}
export const verifyQueryToken = memoize(verifyQueryTokenImpl);
